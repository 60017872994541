import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';

const Home = lazy(() => import('./Home'));

export default (
  <>
    <Route path="/" element={<Home />} />
    <Route path="*" element={<NotFoundPage />} />
  </>
);
