import { BrowserRouter } from 'react-router-dom';
import '~/styles/app.css';
import getI18n from './app.i18n';
import AppRouter from './AppRouter';

export default function App() {
  getI18n();

  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}
