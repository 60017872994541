import { Suspense, useMemo } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import { dashboardModule } from './dashboard';
import { homeModule } from './home';
import { embedModule } from './embed';

import { Header } from './layout';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

export default function AppRouter() {
  const location = useLocation();

  const isHeaderNecessary = useMemo(() => !location.pathname.includes('embed'), [location]);
  const LoadingMessage = () => <div>Loading...</div>;

  return (
    <main className="main">
      {isHeaderNecessary && <Header />}
      <Suspense fallback={<LoadingMessage />}>
        <AuthenticatedTemplate>
          <Routes>
            {homeModule.routes}
            {dashboardModule.routes}
            {embedModule.routes}
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>{homeModule.routes}</Routes>
        </UnauthenticatedTemplate>
      </Suspense>
    </main>
  );
}
