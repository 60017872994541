import { createRoot } from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import App from './app/App';
import { msalConfig } from './app/shared/auth';
import axios from 'axios';
import qs from 'qs';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

axios.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });

const root = createRoot(document.getElementById('root'));

root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
);
