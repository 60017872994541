import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import image from '~/assets/images/logo.svg';
import './header.css';
import { useNavigate } from 'react-router-dom';
import { silentLogin } from '~/app/shared/auth/auth-config';

export function Header() {
  const [t] = useTranslation();
  const { instance } = useMsal();
  const navigate = useNavigate();

  const onLogin = async () => {
    try {
      await silentLogin();
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
    }
  };

  const onLogout = async () => {
    try {
      await instance.logoutRedirect();
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Navbar className="header">
      <Container className="header__container">
        <div className="header__logos">
          <Navbar.Brand className="header__link" href="/">
            <img alt="" src={image} height="150" className="header__logo" />
          </Navbar.Brand>

          <h1 className="header__title">{t('header.title')}</h1>
        </div>
        <div>
          {useIsAuthenticated() ? (
            <Button onClick={onLogout} className="header__button header__button--logout">
              {t('home.logout')}
            </Button>
          ) : (
            <Button onClick={onLogin} className="header__button">
              {t('home.login')}
            </Button>
          )}
        </div>
      </Container>
    </Navbar>
  );
}
