import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from '~/index';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `${process.env.REACT_APP_AUTH_INSTANCE}/${process.env.REACT_APP_AUTH_TENANTID}`,
    redirectUri: window.location.origin + '/dashboard',
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
};

export const authRequestConfig = {
  scopes: process.env.REACT_APP_AUTH_SCOPE.split(' ')
};

export async function getToken() {
  const tokenRequest = {
    scopes: authRequestConfig.scopes
  };
  try {
    const response = await msalInstance.acquireTokenSilent(tokenRequest);
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      try {
        const response = await msalInstance.acquireTokenPopup(tokenRequest);
        return response.accessToken;
      } catch (error) {
        console.error('Error acquiring token on popup:', error);
        throw error;
      }
    } else {
      console.error('Error acquiring token:', error);
      throw error;
    }
  }
}

export async function silentLogin() {
  const tokenRequest = {
    scopes: authRequestConfig.scopes
  };
  try {
    await msalInstance.ssoSilent(tokenRequest);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      try {
        await msalInstance.loginRedirect(tokenRequest);
      } catch (error) {
        console.error('Error at login:', error);
        throw error;
      }
    } else {
      console.error('Error at login:', error);
      throw error;
    }
  }
}
