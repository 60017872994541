import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// English
import enJSON from '../assets/locales/en.json';

export enum Language {
  English = 'en'
}

const resources = {
  [Language.English]: {
    translation: enJSON
  }
};

function getI18n() {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: Language.English, // Change current language on the end of this file
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
}

export default getI18n;
export const t = i18n.t;

export function getCurrentLanguage(): string {
  return i18n.language;
}

export function changeCurrentLanguage(language: Language): void {
  i18n.changeLanguage(language);
}
